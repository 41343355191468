import { configureStore } from "@reduxjs/toolkit";
import { CommonReducer } from "./Reducer";

export const SetRole = (isLogin, role, email) => {
  return {
    type: "Role",
    payload: { isLogin: isLogin, role: role, email: email },
  };
};

export const SetLoadingOverlay = (isLoading) => {
  return {
    type: "LoadingOverlay",
    payload: isLoading,
  };
};

export const setIsCollapse = (isCollapse) => {
  return{
    type: "SideBar",
    payload: isCollapse,
  }
}

export const setIsAdmin = (isAdmin) => {
  return{
    type: "IsAdmin",
    payload: isAdmin,
  }
}

export const Store = configureStore({
  reducer: CommonReducer,
});
