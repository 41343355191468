import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const CommonModel = (props) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: props.size,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    height: "auto",
    borderRadius: ".5rem",
  };

  return (
    <Modal
      open={props.isOpen}
      onClose={() => props.onCloseModel()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div>
          <div
            className="float-right cursor-pointer p-[11px] hover:bg-[rgb(206,63,63)] content-center"
            onClick={props.onCloseModel}
          >
            <CloseIcon />
          </div>
          <h2 className="font-semibold p-3 bg-[#968049] border-b-1 border-black">
            {props.header}
          </h2>
        </div>
        <div
          className="w-full overflow-auto"
          style={
          {maxHeight: "65vh"}
          }
        >
          {props.body}
        </div>
        {props.footer.isVisible && (
          <>
            <hr className="bg-neutral-950" />
            <div className="flex justify-around m-4">
              {props.footer.iscancelVisible && (
                <Button
                  variant="outlined"
                  className="caps-text-size"
                  size="small"
                  onClick={props.onCloseModel}
                  color="error"
                >
                  {props.buttonName.cancel}
                </Button>
              )}
              {props.footer.isSubmitVisible && (
                <Button
                  variant="contained"
                  size="small"
                  className="caps-text-size"
                  onClick={(e) => props.onSubmit(e)}
                  endIcon={props.buttonName.submit === "Next" ? <NavigateNextIcon /> : ""}
                  disabled={props.footer.isSubmitDisable}
                >
                  {props.buttonName.submit}
                </Button>
              )}
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};

CommonModel.defaultProps = {
  header: "",
  onCloseModel: () => {},
  onClearModel: () => {},
  onSubmit: () => {},
  body: "",
  size: "70%",
  buttonName: { submit: "", cancel: "" },
  footer: { isVisible: false, iscancelVisible: false, isSubmitVisible: false, iscancelVisible: true, isSubmitDisable: false },
};

export default CommonModel;
