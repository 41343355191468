import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SetLoadingOverlay } from "../../Redux/Action";
import { getMkSystem } from "../../CommonFunctions/ApiCalls";
import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReportIcon from "@mui/icons-material/Report";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";

const MasterKeySystem = (props) => {
  const [mkSystem, setMkSystem] = useState({});

  useEffect(() => {
    mkSystemApi();
  }, []);

  let agreementId = useParams("agreementId");

  const mkSystemApi = async () => {
    props.SetLoadingOverlay(true);
    await getMkSystem(agreementId.agreementId)
      .then((data) => {
        if (data) {
          setMkSystem(data);
        }
      })
      .catch((e) => console.log(e));
    props.SetLoadingOverlay(false);
  };

  const getAccordion = (value, title) => {
    return (
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            backgroundColor: "#968049",
            m: 0,
            maxHeight: "48px",
            "&.MuiAccordionSummary-root.Mui-expanded": {
              minHeight: "0px",
            },
          }}
        >
          <Typography color={"common.white"}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            {mkSystem && mkSystem[value] ? (
              Object.keys(mkSystem[value]).map((each) => (
                <li className="my-2">
                  <LabelImportantIcon
                    style={{ transition: 'rotate("30%")' }}
                    color="primary"
                    className="mx-2"
                    fontSize="small"
                  />
                  <span className="font-semibold">
                    {each.charAt(0).toUpperCase() + each.slice(1)}
                  </span>
                  {`:  ${
                    mkSystem[value][each] !== null
                      ? typeof mkSystem[value][each] === "boolean"
                        ? mkSystem[value][each]
                          ? "yes"
                          : "No"
                        : mkSystem[value][each]
                      : ""
                  }`}
                </li>
              ))
            ) : (
              <li className="font-semibold">
                {" "}
                <ReportIcon color="warning" />
                No Data found
              </li>
            )}
          </ul>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <div className="flex flex-row w-full gap-4">
      <div className="w-1/2">
        {getAccordion("dealer", "Customer Information")}
        {getAccordion("distributor", "Distributor Information")}
        {getAccordion("productType", "Product Type")}
      </div>
      <div className="w-1/2">
        {getAccordion("endUser", "End User Information")}
        {getAccordion("questionPointOfContact", "Question point of contact")}
        {getAccordion("specialStamping", "Special Stamping")}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    SetLoadingOverlay: (isLoading) => dispatch(SetLoadingOverlay(isLoading)),
  };
};

export default connect(null, mapDispatchToProps)(MasterKeySystem);
