import { LogLevel } from "@azure/msal-browser";

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1A_V2_SIGNIN",
        // editProfile: "B2C_1_ProfileEditPolicy"
    },
    authorities: {
        signUpSignIn: {
            authority: process.env.REACT_APP_AUTHORITY
        },
        // editProfile: {
        //     authority: "https://msidlabb2c.b2clogin.com/msidlabb2c.onmicrosoft.com/B2C_1_ProfileEditPolicy"
        // }
    },
    authorityDomain: process.env.REACT_APP_AUTHORITY_DOMAIN
}
// Config object to be passed to Msal on creation
export const msalConfig = {
    
    auth: {
        clientId:process.env.REACT_APP_CLIENT_ID,
        authority: process.env.REACT_APP_AUTHORITY,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: process.env.REACT_APP_REDIRECT_URI,
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
 
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie:true,
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

export const functionAPIRequest = {
    scopes:["openid", process.env.REACT_APP_SCOPE],
    forceRefresh: false
    //scopes: ["api://6c9349a4-05d6-4e2a-ba50-2929023f8012/user_impersonation"],
    // scopes: [`api://${process.env.REACT_APP_AAD_APP_CLIENT_ID}/user_impersonation`],
    //extraScopesToConsent: [ "User.Read","profile"]
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["openid", process.env.REACT_APP_SCOPE],
    forceRefresh: false
};

export const graphConfig = {
    graphMeEndpoint:"https://graph.microsoft.com/v1.0/me?$expand=manager($select=id,displayName,mail,userPrincipalName,jobTitle)",
    graphGroupDetailsEndpoint: "https://graph.microsoft.com/v1.0/me/memberOf/microsoft.graph.group?$count=true&$orderby=displayName&$filter=startswith(displayName, 'G-AMER-USSAL-CIS')",
    usersGroupDetailsEndpoint: 'https://graph.microsoft.com/v1.0/users/20aadf3e-df18-4bf7-a888-42d282681bdf/memberOf/microsoft.graph.group',
}