import { Button } from "@mui/material";
import { connect } from "react-redux";
import { NavLink, useParams } from "react-router-dom";

const CreditCardForm = (props) => {
    let agreementId = useParams("agreementId");
    const handleInputChange = ({ target }) => {
      if (target.name === "number") {
        target.value = formatCreditCardNumber(target.value);
      } else if (target.name === "expiry") {
        target.value = formatExpirationDate(target.value);
      } else if (target.name === "cvc") {
        target.value = formatCVC(target.value);
      }
    };
  
    const formatCreditCardNumber = (value) => {
      if (!value) {
        return value;
      }
      const clearValue = clearNumber(value);
      let nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`;
      return nextValue.trim();
    };
  
    const formatCVC = (value, prevValue, allValues = {}) => {
      const clearValue = clearNumber(value);
      let maxLength = 4;
      if (allValues.number) {
        maxLength = 3;
      }
      return clearValue.slice(0, maxLength);
    };
  
    const formatExpirationDate = (value) => {
      const clearValue = clearNumber(value);
      if (clearValue.length >= 3) {
        return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
      }
      return clearValue;
    };
  
    const clearNumber = (value = "") => {
      return value.replace(/\D+/g, "");
    };
  
    return (
      <div
        style={{
          border: "1px solid #999",
          borderRadius: "8px",
          boxShadow: "0 0 8px #999",
          padding: "6px",
        }}
      >
        <div className="m-5">
          <div className="grid grid-cols-4 gap-3">
            <label className="text-size">Card Name</label>
            <div className="col-span-3">
              <input
                type="text"
                name="name"
                className="border border-gray-400 focus:outline-none focus:ring-[#968049] active:ring-inset focus:ring-1 rounded-lg p-2.5 h-[25px] w-72 text-size"
                onChange={handleInputChange}
                //value={""}
              />
            </div>
            <label className="text-size">Card Number</label>
            <div className="col-span-3">
              <input
                type="text"
                name="number"
                className="border border-gray-400 focus:outline-none focus:ring-[#968049] active:ring-inset focus:ring-1 rounded-lg p-2.5 h-[25px] w-72 text-size"
                onChange={handleInputChange}
                //value={""}
              />
            </div>
            <div className="col-span-2">
              <label className="text-size">Expiration</label>
              <input
                type="text"
                name="expiry"
                className="border border-gray-400 focus:outline-none focus:ring-[#968049] active:ring-inset focus:ring-1 rounded-lg p-2.5 h-[25px] w-20 ml-10 text-size"
                onChange={handleInputChange}
                //   value={""}
              />
            </div>
            <div className="col-span-2">
              <label className="text-size">CVV</label>
              <input
                type="password"
                name="cvc"
                className="border border-gray-400 focus:outline-none focus:ring-[#968049] active:ring-inset focus:ring-1 rounded-lg p-2.5 h-[25px] w-20 ml-10 text-size"
                onChange={handleInputChange}
                //   value={""}
              />
            </div>
          </div>
          <div className="text-center mt-5">
            <NavLink
              to={
                props.userRole.role === "end-user"
                  ? `/end-user/agreement/${agreementId.agreementId}`
                  : `/customer/agreement/${agreementId.agreementId}`
              }
            >
              <Button
                variant="contained"
                size="small"
                className="button-text-decor"
                style={{ position: "relative", bottom: "0" }}
                onClick={() => props.setIsOpen({ open: false, type: null })}
              >
                Process Coining Die
              </Button>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
  
  const mapStateToProps = (state) => {
    return {
      userRole: state.userRole,
    };
  };

  export default connect(mapStateToProps, null)(CreditCardForm);