import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { getAgreementBYId } from "../CommonFunctions/ApiCalls";
import { SetLoadingOverlay } from "../Redux/Action";
import { connect } from "react-redux";

const ProgramDescription = (props) => {
  const [agreement, setAgreement] = useState([]);

  let agreementId = useParams("agreementId");

  const navigate = useNavigate();

  useEffect(() => {
    getAgreement(agreementId.agreementId);
  }, []);

  const getAgreement = async (agreementId) => {
    props.SetLoadingOverlay(true);
    await getAgreementBYId(agreementId)
      .then((data) => {
        if (data) {
          setAgreement(data);
        }
      })
      .catch((e) => console.log(e, "erro"));
    props.SetLoadingOverlay(false);
  };

  return (
    <div>
      <div className="my-3">
        <label className="font-bold text-2xl">Program Description</label>
      </div>
      <div className="w-full">
        <div
          style={{
            height: "calc(100% - 60px)",
            border: "1px solid #999",
            borderRadius: "2px",
            boxShadow: "0 0 8px #999",
          }}
          className="float-right mx-5"
          dangerouslySetInnerHTML={{
            __html: agreement.agreementType?.videoURL,
          }}
        ></div>
        <div
          className="text-justify"
          dangerouslySetInnerHTML={{
            __html: agreement.agreementType?.description,
          }}
        ></div>
      </div>
      <div className="text-center m-5">
        <Button
          variant="contained"
          size="medium"
          onClick={() =>
            navigate(
              `/${props.userRole.role}/backup/${agreementId.agreementId}`
            )
          }
        >
          Get Started!
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SetLoadingOverlay: (isLoading) => dispatch(SetLoadingOverlay(isLoading)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramDescription);
