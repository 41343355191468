export const TopNavMenu = {
  menu: ["AGREEMENT", "FORMS", "COINING DIE GENERATION"],
};

export const fields = {
  newAgreement: [
    { firstName: "First Name" },
    { lastName: "Last Name" },
    { emailAddress: "Email Address" },
    { phoneNumber: "Phone Number" },
    { customerTypeId: "Customer Type" },
    { companyName: "Company Name" },
    { country: "Country" },
    { address: "Address" },
    { city: "City" },
    { stateId: "State" },
    { postalCode: "Postal Code" },
    { technologyId: "Technology" },
    { agreementTypeId: "Agreement Type" },
  ],
  editAgreement: [
    { companyName: "Company Name" },
    { address: "Address" },
    { city: "City" },
    { stateId: "State" },
    { postalCode: "Postal Code" },
    { country: "Country" },
    { technologyId: "Technology" },
    { agreementTypeId: "Agreement Type" },
    { customerTypeId: "Customer Type" },
  ],
  register: [
    { userType: "User Type" },
    { firstName: "First Name" },
    { lastName: "Last Name" },
    { emailAddress: "Email Address" },
    { companyName: "Company Name" },
  ],
  backup: [
    { agreementFirstName: "First Name" },
    { agreementLastName: "Last Name" },
    { agreementEmailAddress: "Email Address" },
    { agreementPhoneNumber: "Phone Number" },
    { keysystemFirstName: "First Name" },
    { keysystemLastName: "Last Name" },
    { keysystemEmailAddress: "Email Address" },
    { keysystemPhoneNumber: "Phone Number" },
    { BackupFirstName: "First Name" },
    { BackupLastName: "Last Name" },
    { BackupEmailAddress: "Email Address" },
    { BackupPhoneNumber: "Phone Number" },
  ],
};

export const editContact = [
  {
    field: "isPrimary",
    headerName: "Is Primary",
    minWidth: 100,
    inEdit: false,
    format: "boolean",
    editor: "boolean",
  },
  {
    field: "title",
    headerName: "Title",
    minWidth: 120,
    inEdit: true,
    editor: "textbox"
  },
  {
    field: "firstName",
    headerName: "First Name",
    minWidth: 150,
    inEdit: true,
    required: true,
    editor: "textbox"
  },
  {
    field: "lastName",
    headerName: "Last Name",
    minWidth: 150,
    inEdit: true,
    required: true,
    editor: "textbox"
  },
  {
    field: "emailAddress",
    headerName: "Email Address",
    minWidth: 167,
    inEdit: true,
    required: true,
    editor: "textbox"
  },
  {
    field: "phoneNumber",
    headerName: "Phone Number",
    minWidth: 130,
    inEdit: true,
    required: true,
    editor: "textbox"
  },
  {
    field: "actions",
    headerName: "Actions",
    minWidth: 140,
    locked: true,
  },
];

export const endUserAgreement = [
  {
    field: "action",
    headerName: "Action",
    minWidth: 130,
    headerClassName: "bg-[#1222]",
    filterable: false,
  },
  {
    field: "isComplete",
    headerName: "Complete?",
    headerClassName: "bg-[#1222]",
    minWidth: 150,
    filterable: false,
    format: "boolean",
  },
  {
    field: "requestedDate",
    headerName: "Request Date",
    minWidth: 250,
    headerClassName: "bg-[#1222]",
    filterable: false,
    format: "date",
  },
  {
    field: "technology",
    headerName: "Technology",
    flex: 1,
    minWidth: 250,
    headerClassName: "bg-[#1222]",
  },
  {
    field: "keyway",
    headerName: "Keyway",
    minWidth: 200,
    headerClassName: "bg-[#1222]",
  },
];

export const salesAgreement = [
  {
    field: "action",
    headerName: "Action",
    minWidth: 70,
    headerClassName: "bg-[#1222]",
    filterable: false,
  },
  {
    field: "isComplete",
    headerName: "isComplete?",
    minWidth: 110,
    headerClassName: "bg-[#1222]",
    filterable: false,
    format: "boolean",
  },
  {
    field: "requestedDate",
    headerName: "Request Date",
    minWidth: 200,
    headerClassName: "bg-[#1222]",
    filterable: false,
    format: "date",
  },
  {
    field: "aggType",
    headerName: "Type",
    minWidth: 200,
    headerClassName: "bg-[#1222]",
  },
  {
    field: "technology",
    headerName: "Technology",
    minWidth: 200,
    headerClassName: "bg-[#1222]",
  },
  {
    field: "companyName",
    headerName: "Company Name",
    minWidth: 250,
    headerClassName: "bg-[#1222]",
  },
  {
    field: "firstName",
    headerName: "First Name",
    minWidth: 200,
    headerClassName: "bg-[#1222]",
  },
  {
    field: "lastName",
    headerName: "Last Name",
    minWidth: 200,
    headerClassName: "bg-[#1222]",
  },
  {
    field: "emailAddress",
    headerName: "Email Address",
    minWidth: 240,
    headerClassName: "bg-[#1222]",
  },
  {
    field: "phoneNumber",
    headerName: "Phone Number",
    minWidth: 150,
    headerClassName: "bg-[#1222]",
  },
];

export const keyScheduleInternal = [
  {
    field: "line",
    headerName: "Line",
    minWidth: 100,
    headerClassName: "bg-[#1222]",
    filterable: false,
    inEdit: false
  },
  {
    field: "cylinderQty",
    headerName: "Cylinder Qty",
    minWidth: 100,
    headerClassName: "bg-[#1222]",
    filterable: false,
    inEdit: true,
    required: true
  },
  {
    field: "doorNumber",
    headerName: "Door Number",
    minWidth: 140,
    headerClassName: "bg-[#1222]",
    filterable: false,
    inEdit: true,
    required: true
  },
  {
    field: "partNumber",
    headerName: "Part Number",
    minWidth: 170,
    headerClassName: "bg-[#1222]",
    filterable: false,
    inEdit: true,
    required: true
  },
  {
    field: "finish",
    headerName: "Finish",
    minWidth: 140,
    headerClassName: "bg-[#1222]",
    filterable: false,
    inEdit: true,
    required: true,
  },
  {
    field: "keyQuantity",
    headerName: "Key Qty",
    minWidth: 100,
    headerClassName: "bg-[#1222]",
    filterable: false,
    inEdit: true,
    required: true
  },
  {
    field: "keyset",
    headerName: "Key Set",
    minWidth: 140,
    headerClassName: "bg-[#1222]",
    filterable: false,
    inEdit: true,
    required: true
  },
];

export const keyScheduleField = [
  "agreementId",
  "Cylinder_Qty",
  "Door_Number",
  "Part_Number",
  "Finish",
  "Keyset",
  "Key_Quantity",
];
