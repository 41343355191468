import { NavLink } from "react-router-dom";
import { TopNavMenu } from "./CommonConstants";

let topNavContent = TopNavMenu.menu;
const TopNav = () => {
  return (
    <div className="bg-white h-6 flex justify-center gap-4 items-center">
      {topNavContent.map((menu, index) => {
        return (
          <>
            <NavLink
            to={`/end-user/${menu.toLowerCase()}`}
              className={"font-semibold text-base top-nav"}
            >
              {menu}
            </NavLink>
            <span>{index !== topNavContent.length - 1 && "|"}</span>
          </>
        );
      })}
    </div>
  );
};

export default TopNav;
