const initialState = {
  userRole: {
    isLogin: window.location.pathname === "/" ? false : true,
    role:
      window.location.pathname === "/"
        ? ""
        : window.location.pathname.includes("sales")
        ? "sales"
        : window.location.pathname.includes("end-user")
        ? "end-user"
        : window.location.pathname.includes("internal")
        ? "internal"
        : window.location.pathname.includes("customer")
        ? "customer"
        : "",
      email: "",
  },
  isLoading: false,
  isCollapse: true,
  isAdmin: false
};

export const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case "Role":
      return {
        ...state,
        userRole: action.payload,
      };
    case "LoadingOverlay":
      return {
        ...state,
        isLoading: action.payload,
      };
      case "SideBar" :
        return {
          ...state,
          isCollapse: action.payload,
        }
      case "IsAdmin" :
        return {
          ...state,
          isAdmin: action.payload,
        }
    default:
      return state;
  }
};
