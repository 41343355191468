import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { Store } from "./Redux/Action";
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";
import { BrowserRouter } from "react-router-dom";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event) => {
  console.log(event, "msalInstance inside callback - event");
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    console.log(EventType.LOGIN_SUCCESS, "eventss", event.payload.account);
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
if (window.location.hash !== "") {
} else {
  root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Provider store={Store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </MsalProvider>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
