import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import {
  getAgreementHtml,
  setAgreementSigned,
} from "../../CommonFunctions/ApiCalls";
import { SetLoadingOverlay } from "../../Redux/Action";
import { connect } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import ConfirmBox from "../../MasterComponent/ConfirmBox";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReplyIcon from "@mui/icons-material/Reply";

const SignAgreement = (props) => {
  const [agreementIframe, setAgreementIfram] = useState();
  const [isOpen, setIsOpen] = useState(false);

  let agreementId = useParams("agreementId");

  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem(`optValidity_${agreementId.agreementId}`) === null &&
      new Date(localStorage.getItem(`optValidity_${agreementId.agreementId}`)) <
        new Date()
    ) {
      navigate(`/${props.userRole.role}/agreement/${agreementId.agreementId}`);
    }
    getAgreementIframe();
  }, []);

  const getAgreementIframe = async () => {
    props.SetLoadingOverlay(true);
    await getAgreementHtml(agreementId.agreementId)
      .then((data) => {
        if (data) {
          setAgreementIfram(data);
        }
      })
      .catch((e) => console.log(e, "erro"));
    props.SetLoadingOverlay(false);
  };

  const handleCompleteAgreement = async () => {
    props.SetLoadingOverlay(true);
    await setAgreementSigned(agreementId.agreementId)
      .then(async (result) => {
        if (result && result.statusCode === 200) {
          setIsOpen(false);
          var nav =
            props.userRole.role === "end-user"
              ? `/end-user/agreement/${agreementId.agreementId}`
              : `/customer/agreement/${agreementId.agreementId}`;
          navigate(nav);
        } else {
          toast.error("Something went wrong Please try again later !!!");
        }
      })
      .catch((e) => {
        toast.error("Something went wrong Please try again later !!!");
        console.log(e, "err");
      });
    props.SetLoadingOverlay(false);
  };

  return (
    <div className="h-full">
      <div className="flex justify-between">
        <div className="mt-2 font-semibold">M4 Enduser Agreement</div>
        <div className="m-2">
          {/* <Button
            variant="contained"
            size="small"
            startIcon={<TaskOutlinedIcon />}
            sx={{ marginRight: "8px" }}
            onClick={() => setIsOpen(true)}
          >
            Agreement Complete
          </Button> */}
          <NavLink
            to={
              props.userRole.role === "end-user"
                ? `/end-user/agreement/${agreementId.agreementId}`
                : `/customer/agreement/${agreementId.agreementId}`
            }
          >
            <Button
              variant="outlined"
              size="small"
              startIcon={<ReplyIcon />}
              className="caps-text-size button-text-decor"              
            >
              Back
            </Button>
          </NavLink>
        </div>
      </div>
      <div
        style={{
          height: "calc(100% - 45px)",
          border: "1px solid #999",
          borderRadius: "2px",
          boxShadow: "0 0 8px #999",
        }}
        dangerouslySetInnerHTML={{ __html: agreementIframe?.agreementHTML }}
      ></div>
      <ConfirmBox
        isOpen={isOpen}
        onSubmitModel={() => handleCompleteAgreement()}
        onCloseModel={() => setIsOpen(false)}
        message={"Are you sure, Let's Complete Agreement"}
        type="update"
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    SetLoadingOverlay: (isLoading) => dispatch(SetLoadingOverlay(isLoading)),
  };
};

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignAgreement);
