import { Button, MenuItem, Select } from "@mui/material";
import TopNav from "../MasterComponent/TopNav";

export const Forms = () => {
  return (
    <>
      <TopNav />
      <div className="grid grid-cols-3 gap-2 mt-5">
        <div className="grid grid-cols-2 gap-2 col-span-2 gap-y-5">
          <lable className="text-size">
            What level of key control do you need ?
          </lable>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            className={`border-0 text-gray-900 text-xs rounded-lg h-[1.6rem] m-0 w-full`}
            value={"0"}
          >
            <MenuItem value="0">Please Select ...</MenuItem>
          </Select>
          <lable className="text-size">
            What technology will you utilize ?
          </lable>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            className={`border-0 text-gray-900 text-xs rounded-lg h-[1.6rem] m-0 w-full`}
            value={"0"}
          >
            <MenuItem value="0">Please Select ...</MenuItem>
          </Select>
          <lable className="text-size">What is the size of your system ?</lable>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            className={`border-0 text-gray-900 text-xs rounded-lg h-[1.6rem] m-0 w-full`}
            value={"0"}
          >
            <MenuItem value="0">Please Select ...</MenuItem>
          </Select>
          <lable className="text-size">
            What level of security do you need ?
          </lable>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            className={`border-0 text-gray-900 text-xs rounded-lg h-[1.6rem] m-0 w-full`}
            value={"0"}
          >
            <MenuItem value="0">Please Select ...</MenuItem>
          </Select>
          <lable className="text-size">What is your ordering channel ?</lable>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            className={`border-0 text-gray-900 text-xs rounded-lg h-[1.6rem] m-0 w-full`}
            value={"0"}
          >
            <MenuItem value="0">Please Select ...</MenuItem>
          </Select>
          <div className="col-span-2 text-center">
            <Button variant="contained" size="small" className="w-60 ">
              GET MY RESULT
            </Button>
          </div>
        </div>
        <div>
            
        </div>
      </div>
    </>
  );
};
