import { NavLink } from "react-router-dom";
import medeco from "../Assets/FusionLogo.png";
import assaabloy from "../Assets/assaabloy.png";
import LogoutIcon from "@mui/icons-material/Logout";
import { SetLoadingOverlay, SetRole } from "../Redux/Action";
import { connect } from "react-redux";
import { IconButton, Tooltip } from "@mui/material";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import ContactSupport from "@mui/icons-material/ContactSupport";

const Header = (props) => {
  return (
    <header className="w-full">
      <div className="bg-[#000000] flex justify-between items-center headerRow">
        <img alt="assa-logo" src={assaabloy} className="assaLogo" />
      </div>
      <div className="bg-[#ffffff] flex justify-between items-center">
        <NavLink to={process.env.REACT_APP_FUSION_URL}>
          <img
            alt="medeco-logo"
            src={medeco}
            className="p-2"
            style={{ height: "59px", minHeight: "59px", maxHeight: "59px" }}
          />
        </NavLink>
        <div className="mr-4">
          {props.accounts && props.accounts.length > 0 && (
            <>
              <span className="font-semibold text-base px-2">
                Hello,{" "}
                {props.accounts &&
                  props.accounts.length > 0 &&
                  props.accounts[0].name}
              </span>
              {props.accounts && props.accounts.length > 0 && (
                <span className="">
                  <IconButton onClick={() => props.handleLogOut()}>
                    <LogoutIcon htmlColor="#000000" />
                  </IconButton>
                </span>
              )}
            </>
          )}
          <IconButton>
            <TextsmsOutlinedIcon htmlColor="#000000" />
          </IconButton>

          <Tooltip placement="bottom-start" title="contact us">
            <IconButton
              onClick={() => {
                window
                  .open("https://www.medeco.com/en/contact-us", "_blank")
                  .focus();
              }}
            >
              <ContactSupport htmlColor="#000000" alt="Contact Support" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className="bg-[#968049] flex justify-between items-center headerRow">
        <p className="w-full text-center">ON BOARDING</p>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SetRole: (isLogin, role) => dispatch(SetRole(isLogin, role)),
    SetLoadingOverlay: (isLoading) => dispatch(SetLoadingOverlay(isLoading)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
