import { fields } from "../MasterComponent/CommonConstants";

export const handleFormValidation = (
  fieldName = null,
  fieldValue = null,
  errorMessage,
  agreement
) => {
  console.log(fieldName, fieldValue, errorMessage, agreement, "validation");
  let validationFaildMessage =
    errorMessage && errorMessage.length > 0 ? [...errorMessage] : [];
  if (!fieldValue) {
    if (fieldName === "emailAddress" || fieldName == "phoneNumber") {
      if (
        validationFaildMessage[
          validationFaildMessage.findIndex((a) => a.fieldName === fieldName)
        ]
      ) {
        validationFaildMessage[
          validationFaildMessage.findIndex((a) => a.fieldName === fieldName)
        ] = {
          fieldName: fieldName,
          errorMessage: `Please enter ${
            fields.newAgreement.find((each) => each[fieldName])[fieldName]
          }`,
        };
      } else {
        validationFaildMessage.push({
          fieldName: fieldName,
          errorMessage: `Please enter ${
            fields.newAgreement.find((each) => each[fieldName])[fieldName]
          }`,
        });
      }
    } else {
      validationFaildMessage.push({
        fieldName: fieldName,
        errorMessage: `Please enter ${
          fields.newAgreement.find((each) => each[fieldName])[fieldName]
        }`,
      });
    }
  } else {
    if (fieldValue && fieldValue !== "0") {
      validationFaildMessage = validationFaildMessage.filter(
        (error) => error.fieldName !== fieldName
      );
      if (fieldName === "emailAddress" && !/\S+@\S+\.\S+/.test(fieldValue)) {
        validationFaildMessage.push({
          fieldName: fieldName,
          errorMessage: `Please enter valid ${
            fields.newAgreement.find((each) => each[fieldName])[fieldName]
          }`,
        });
      }
      if (fieldName === "phoneNumber" && !/^(\d+-?)+\d+$/.test(fieldValue)) {
        validationFaildMessage.push({
          fieldName: fieldName,
          errorMessage: `Please enter valid ${
            fields.newAgreement.find((each) => each[fieldName])[fieldName]
          }`,
        });
      }
    } else {
      if (fieldValue === "0") {
        validationFaildMessage.push({
          fieldName: fieldName,
          errorMessage: `Please Select ${
            fields.newAgreement.find((each) => each[fieldName])[fieldName]
          }`,
        });
      } else {
        if (
          validationFaildMessage[
            validationFaildMessage.findIndex((a) => a.fieldName === fieldName)
          ]
        ) {
          validationFaildMessage[
            validationFaildMessage.findIndex((a) => a.fieldName === fieldName)
          ] = {
            fieldName: fieldName,
            errorMessage: `Please enter ${
              fields.newAgreement.find((each) => each[fieldName])[fieldName]
            }`,
          };
        } else {
          validationFaildMessage.push({
            fieldName: fieldName,
            errorMessage: `Please enter ${
              fields.newAgreement.find((each) => each[fieldName])[fieldName]
            }`,
          });
        }
      }
    }
  }
  if (
    (fieldName === "customerTypeId" || fieldName === "technologyId") &&
    agreement["agreementTypeId"] === 0
  ) {
    if (
      validationFaildMessage[
        validationFaildMessage.findIndex(
          (a) => a["agreementTypeId"] === "agreementTypeId"
        )
      ]
    ) {
      validationFaildMessage[
        validationFaildMessage.findIndex(
          (a) => a["agreementTypeId"] === "agreementTypeId"
        )
      ] = {
        fieldName: "agreementTypeId",
        errorMessage: "Please select Agreement Type",
      };
    } else {
      validationFaildMessage.push({
        fieldName: "agreementTypeId",
        errorMessage: "Please select Agreement Type",
      });
    }
  }
  if (fieldName === "agreementTypeId" && agreement["agreementTypeId"] !== 0) {
    validationFaildMessage = validationFaildMessage.filter(
      (error) => error.fieldName !== "technologyId"
    );
  }
  return validationFaildMessage;
};

export const viewErrorMessage = (fieldName, errorMessage) => {
  let error =
    errorMessage &&
    errorMessage.length > 0 &&
    errorMessage.filter((error) => error.fieldName === fieldName);
  if (error?.length > 0) {
    return error[0].errorMessage;
  }
  return;
};

export const deleteItem = (item, key, data) => {
  let index = data.findIndex((record) => record[key] === item[key]);
  data.splice(index, 1);
  return data;
};

export const insertItem = (item, data, key) => {
  // item[key] = generateId(data);
  item.inEdit = false;
  let newData = [...data];
  // newData.unshift(item);
  console.log(newData, item, "changed");
  return newData;
};

export const updateItem = (item, data, key) => {
  let newData = [...data];
  let index = newData.findIndex((record) => record[key] === item[key]);
  newData[index] = item;
  return newData;
};

export const requiredValidator = (value, field) => {
  let err = "";
  console.log(field, value, "checkBox");
  if (value === "" && field !== "title") {
    err = field + " is required";
  } else if (field === "emailAddress" && !/\S+@\S+\.\S+/.test(value)) {
    console.log(!/\S+@\S+\.\S+/.test(value), "validate mail");
    err = "Please enter valid Email Addresss";
  } else if (field === "phoneNumber" && !/^(\d+-?)+\d+$/.test(value)) {
    err = "Please enter valid Phone Number";
  } else if (
    (field === "keyQuantity" ||
      field === "key_Quantity" ||
      field === "cylinder_Qty" ||
      field === "cylinderQty") &&
    !/^\d+$/.test(value)
  ) {
    err = "Please enter a Number";
  }
  console.log(value, "errormsg", err, field);
  return err;
};

export const handleDownload = (resource, name, target) => {
  let url = resource.filter((each) => each.name === name)[0].url;
  console.log(url, "urlsssss", target);
  // Create a temporary anchor element
  const link = document.createElement("a");
  link.target = target;
  link.href = url;
  link.download = name + ".pdf"; // Filename for the downloaded PDF
  document.body.appendChild(link);
  // Trigger the download
  link.click();
  // Clean up
  document.body.removeChild(link);
};

export const handleDownloadImage = (url, name) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = name + ".jpg";
  //  Filename for the downloaded PDF
  document.body.appendChild(link);
  // Trigger the download
  link.click();
  // Clean up
  document.body.removeChild(link);
};

export const isAllUpperCase = (str) => {
  return /^[A-Z]+$/.test(str);
};

export const clearAllOtpData = (localKey) => {
  // If you have a specific pattern for user keys, loop through them
  for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith(localKey)) {
          localStorage.removeItem(key);
      }
  }
}
