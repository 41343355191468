import NotFound from "./NotFound";
import { Route, Routes } from "react-router-dom";
import KeyingSchedule from "../MainComponent/Agreement/KeyingSchedule";
import { Forms } from "../MainComponent/Forms";
import SignAgreement from "../MainComponent/Agreement/SignAgreement";
import Agreement from "../MainComponent/Agreement";
import CoiningDie from "../MainComponent/Agreement/CoiningDie";
import BackUp from "../MainComponent/BackUp";
import ProgramDescription from "../MainComponent/ProgramDescription";
import Home from "../MainComponent/Home";
import ViewAgreement from "../MainComponent/ViewAgreement";
import Login from "./Login";
import MasterKeySystem from "../MainComponent/Agreement/MasterKeySystem";
// import AppDoctor from "../App Doctor/AppDoctor";
import { connect } from "react-redux";

const Routing = (props) => {
  return (
    <Routes>
      {props.userRole.role === "sales" && (
        <>
          <Route exact path="sales" element={<Home />} />
          <Route
            exact
            path="sales/view-agreement/:agreementId"
            element={<ViewAgreement />}
          />
        </>
      )}
      {props.userRole.role === "end-user" && (
        <>
          <Route exact path="end-user" element={<Home />} />
          <Route
            exact
            path="end-user/agreement/:agreementId"
            element={<Agreement />}
          />
          <Route
            exact
            path="end-user/backup/:agreementId"
            element={<BackUp />}
          ></Route>
          <Route
            exact
            path="end-user/key-schedule/:agreementId"
            element={<KeyingSchedule />}
          />
          <Route exact path="end-user/forms" element={<Forms />} />
          <Route
            exact
            path="end-user/sign-agreement/:agreementId"
            element={<SignAgreement />}
          />
          <Route
            exact
            path="end-user/coiningdie/:agreementId"
            element={<CoiningDie />}
          />
          <Route
            exact
            path="end-user/program-description/:agreementId"
            element={<ProgramDescription />}
          />
          <Route
            exact
            path="end-user/masterkey-system/:agreementId"
            element={<MasterKeySystem />}
          />
        </>
      )}
      {props.userRole.role === "customer" && (
        <>
          <Route exact path="customer" element={<Home />} />
          <Route
            exact
            path="customer/agreement/:agreementId"
            element={<Agreement />}
          />
          <Route
            exact
            path="customer/sign-agreement/:agreementId"
            element={<SignAgreement />}
          />
          <Route
            exact
            path="customer/coiningdie/:agreementId"
            element={<CoiningDie />}
          />
          <Route
            exact
            path="customer/program-description/:agreementId"
            element={<ProgramDescription />}
          />
          <Route
            exact
            path="customer/backup/:agreementId"
            element={<BackUp />}
          ></Route>
        </>
      )}
      {props.userRole.role === "internal" && (
        <>
          <Route exact path="internal" element={<Home />} />
          <Route
            exact
            path="internal/view-agreement/:agreementId"
            element={<ViewAgreement />}
          />
          <Route
            exact
            path="internal/coiningdie/:agreementId"
            element={<CoiningDie />}
          />
          <Route
            exact
            path="internal/key-schedule/:agreementId"
            element={<KeyingSchedule />}
          />
          <Route
            exact
            path="internal/masterkey-system/:agreementId"
            element={<MasterKeySystem />}
          />
        </>
      )}
      {/* <Route exact path="health-check" element={<AppDoctor />} /> */}
      <Route exact path="/" element={<Login />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const mapStateToProps = (state) => {
  console.log(state, "accstate");
  return {
    userRole: state.userRole,
  };
};

export default connect(mapStateToProps, null)(Routing);
